import {HtmlToText} from '../utils/utils';
import { json2csv } from 'json-2-csv';

import dsd from "../classes/clsDSD";

export const makeCsv = (LogicalModel = null) => {

    if (!LogicalModel){
        return null;
    }

    if (!LogicalModel.Entities){
        return null;
    }


    let rows = [];

    for (let posC=0;posC<LogicalModel.Entities.length;posC++){
        const Entity = LogicalModel.Entities[posC];
        let row = {
            Entity:'',
            Label:'',
            Description:'',
            AttributeName:'',
            DataType:'',
            Structure:'',
            Occurs:'',
            Required:''
        };

        let rowPrefix = '';
        if (LogicalModel.prefix){
            rowPrefix = `${LogicalModel.prefix}:`
        }
        row.Entity = `${rowPrefix}${Entity.name.replace(/ /g,"_")}`;
        row.Label = Entity.name;
        row.Description = HtmlToText(Entity.description);

        rows.push(row);

        for (let posA=0;posA<Entity.Attributes.length;posA++){
            let Attribute = Entity.Attributes[posA];

            let row = {
                Entity:'',
                Label:'',
                Description:'',
                AttributeName:'',
                DataType:'',
                Structure:'',
                Occurs:'',
                Required:''
            };



            if (Attribute.type){                    
                row.AttributeName = Attribute.name;
                row.DataType = '';
                row.Structure = '';
                row.Occurs = '';
                row.Required = '';
                switch (Attribute.type){
                    case 'structure':

                        let rowPrefix = '';
                        if (Attribute.Structure){
                            if (Attribute.Structure.DataDictionary){
                                if (Attribute.Structure.DataDictionary.prefix){
                                    rowPrefix = `${Attribute.Structure.DataDictionary.prefix}:`;
                                }
                            }
                            row.Structure = `${rowPrefix}${Attribute.Structure.name.replace(/ /g,"_")}`;
                        }
                        break;
                    default:
                        row.DataType = Attribute.dataType;
                        break;
                }
                if (Attribute.occurs){
                    row.Occurs = Attribute.occurs;
                }
                if (Attribute.required){
                    row.Required = Attribute.required;
                }
                rows.push(row);
            }
        }
    }
    
    const csv = json2csv(rows)

    return csv;

}


export const makeXsd = (LogicalModel = null) => {

    if (!LogicalModel){
        return null;
    }

    if (!LogicalModel.Entities){
        return null;
    }

    let Models = {};

// set the namespace for the Logical Model 
    makeXsdModel(Models, LogicalModel);

    let Concepts = {};
    let Links = {};
    let Structures = {};

    // create a Schema object for the Logical Model
// schema object has an xsd property which contains the xsd schema

    let Schemas = {};
    const Schema = getXsdSchema(LogicalModel, Schemas, Models);


// find the structures that are used and set up namespaces for their parent Dictionaries
    for (let posE=0;posE<LogicalModel.Entities.length; posE++){
        let Entity = LogicalModel.Entities[posE];
        if (Entity.Attributes){
            for (let posA=0;posA<Entity.Attributes.length; posA++){
                let Attribute = Entity.Attributes[posA];
                if (Attribute.Structure){
                    let Structure = Attribute.Structure;
                    let Model = makeXsdModel(Models, Structure.DataDictionary);
                    Structures[Structure._id] = Structure;
                    Structure.nodeName = `${makeXsdNode(Structure.name)}`;

                    getXsdSchema(Attribute.Structure.DataDictionary, Schemas, Models)                

                }
            }

        }
    }

// find the links that are used and set up namespaces for their parent Concpet Models
    for (let posR=0; posR<LogicalModel.Relationships.length; posR++){
        let Relationship = LogicalModel.Relationships[posR];
        if (Relationship.Link){
            let Link = Relationship.Link
            if (Link.ConceptModel){
                let Namespace = makeXsdModel(Models, Link.ConceptModel);
                Links[Link._id] = Link;
                Link.nodeName = `${makeXsdNode(Link.name)}`;
            }
        }
    } 


    if (Schema){
        for (let namespace in Schemas){
            let ImportSchema = Schemas[namespace];
            Schema.xsd.att(`xmlns:${ImportSchema.alias}`, ImportSchema.namespace);

            if (ImportSchema.namespace != Schema.namespace){
                let xsdImport = Schema.xsd.ele('xsd:import');
                xsdImport.att('namespace', ImportSchema.namespace);
                xsdImport.att('schemaLocation', ImportSchema.location);
            }
        }
        for (let posE=0;posE<LogicalModel.Entities.length;posE++){
            const Entity = LogicalModel.Entities[posE];
            makeXsdType(Entity, Schema, Schemas, Models);
        }
    }

// create schemas for each referenced Dictionary

    let StructureIds = {}
    for (let idStructure in Structures){
        const Structure = Structures[idStructure];
        makeXsdStructure(Structure, Schemas, Models, StructureIds);
    }


// put all the schemas together into a single text document
    let xsds = {};
    for (let namespace in Schemas){
        xsds[Schemas[namespace].location] = Schemas[namespace].xsd.end({ prettyPrint: true })
    }

    return xsds;

};


export const makeXsdStructure = (Structure, Schemas, Models, Ids={}) => {

    if (Structure._id in Ids){
        return;
    }

    Ids[Structure._id] = Structure;

    if (Structure.DataDictionary){
        let Schema = getXsdSchema(Structure.DataDictionary, Schemas, Models);
        if (Schema){
            makeXsdType(Structure, Schema, Schemas, Models);
        }
    }
        
    if (Structure.Attributes){
        for (let posA=0; posA<Structure.Attributes.length;posA++){
            const Attribute = Structure.Attributes[posA];
            if (Attribute.Structure){
                makeXsdStructure(Attribute.Structure, Schemas, Models, Ids);
            }
        }
    }

}

export const makeXsdType = (Item, Schema, Schemas, Models) => {

    if (!Item){
        return false;
    }
    if (!Item.name){
        return false;
    }
    
    const dsd1 = new dsd();

    let hasAttributes = false;

    if (Item.Attributes){
        for (let posA=0;posA<Item.Attributes.length;posA++){
            let Attribute = Item.Attributes[posA];
            if (Attribute.name){                    
                hasAttributes = true;
            }
        }
    }

    if (!hasAttributes){
        let xsdSimpleType = Schema.xsd.ele('xsd:simpleType');
        xsdSimpleType.att('name', makeXsdNode(Item.name));
        let xsdRestriction = xsdSimpleType.ele('xsd:restriction');
        xsdRestriction.att('base', 'xsd:string');
        let xsdMaxLength = xsdRestriction.ele('xsd:maxLength');
        xsdMaxLength.att('value','0');
        return xsdSimpleType;
    }

    let xsdComplexType = Schema.xsd.ele('xsd:complexType');
    xsdComplexType.att('name', makeXsdNode(Item.name));

    let xsdSequence = xsdComplexType.ele('xsd:sequence');

    for (let posA=0;posA<Item.Attributes.length;posA++){
        let Attribute = Item.Attributes[posA];


        let xsdElement = xsdSequence.ele('xsd:element');
        xsdElement.att('name', makeXsdNode(Attribute.name));
        
        switch (Attribute.required){
            case 'yes':
            case true:
                break;
            default:
                xsdElement.att('minOccurs', '0');
                break;
        }
        switch (Attribute.occurs){
            case 'many':
                xsdElement.att('maxOccurs', 'unbounded');
                break;
        }

        switch (Attribute.type){
            case 'structure':

                if (Attribute.Structure){
                    if (Attribute.Structure.DataDictionary){
                        let ImportSchema = getXsdSchema(Attribute.Structure.DataDictionary, Schemas, Models)
                        let ImportSchemaAlias = '';
                        if (ImportSchema.alias != Schema.alias){
                            ImportSchemaAlias = `${ImportSchema.alias}:`;
                        }
                        if (ImportSchema) {
                            xsdElement.att('type',`${ImportSchemaAlias}${makeXsdNode(Attribute.Structure.name)}`);
                        }
                    }
                }
                break;
            default:
                if (Attribute.dataType){
                    if (Attribute.dataType in dsd1.config.DataTypes){
                        xsdElement.att('type', dsd1.config.DataTypes[Attribute.dataType].type);
                    }
                }

                break;
        }
    }

    return true;

}

export const makeXsdModel = (Models = {}, Model = null) => {

    if (!Model){
        return;
    }

    if (Model._id in Models){
        return Models[Model._id];
    }

    let namespace = null;

    if (Model.namespace){
        namespace = Model.namespace;
    }

    Models[Model._id] = {
        namespace:namespace,
        id: Model._id
    }

    return Models[Model._id];

}

export const makeXsdNode = (name) => {

    let node = name;
    node = node.replace(/ /g,'_');
    node = node.replace('/{/g','_');
    node = node.replace(/}/g,'_');
    node = node.replace(/\[/g,'_');
    node = node.replace(/\]/g,'_');
    node = node.replace(/\?/g,'');


    return node;
}

export const getXsdSchema = (Model, Schemas, Models) => {

    if (!Model){
        return false;
    }
    if (!(Model._id in Models)){
        return false;
    }
    if (Model.namespace in Schemas){
        return Schemas[Model.namespace];
    }

    let Schema = {};
    Schema.namespace = Model.namespace;
    Schema.alias = `ns${Object.keys(Schemas).length +1}`;

    if (Model.prefix){
        Schema.alias = Model.prefix;
    }

    Schema.location = `${makeXsdNode(Model.name)}.xsd`;

    const { create } = require('xmlbuilder2');
    const nsXSD = 'http://www.w3.org/2001/XMLSchema';


    let options = {};
    options.encoding = 'UTF-8';        

    Schema.xsd = create(options).ele(nsXSD,'xsd:schema');
    if (Schema.namespace){
        Schema.xsd.att('xmlns', Schema.namespace);
        Schema.xsd.att('targetNamespace', Schema.namespace);
    }        

    Schemas[Model.namespace] = Schema;

    return Schema;

}