import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';


import Buffering from "../components/Buffering"; 

import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewConcept from "../components/ViewConcept";
import ViewConceptModel from "../components/ViewConceptModel";
import ViewMetadata from "../components/ViewMetadata";


import SuperConceptRow from "../components/SuperConceptRow"

import ListConcepts from "../components/ListConcepts"

import Revision from "../components/Revision"
import ListRevisions from "../components/ListRevisions"



import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

class Concept extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            renderState: null
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);


        this.refRevision = React.createRef();
        this.loadedRevisions = this.loadedRevisions.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/`;

    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.vizNodeId){
                let partsVizNodeId = this.state.vizNodeId.split('_');
                switch(partsVizNodeId[0]){
                    case 'concept':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/concept/${partsVizNodeId[1]}`);
                        }
                        break;
                }
            }


            if (this.state.loadConceptModel){
                this.loadConceptModel();
            }

            if (this.state.loadConcept){
                this.loadConcept();
            }

            if (this.state.renderState == "loading"){
                if (this.state.isFailed){
                    StateChange.renderState = "failed";
                }
                else{
                    switch (this.state.mode){
                        case "new":
                            if (this.state.isLoadedConceptModel){
                                StateChange.renderState = "loaded";
                            }
                        default:
                            if (this.state.isLoadedConcept){
                                StateChange.ConceptModel = this.state.Concept.ConceptModel;
                                StateChange.renderState = "loaded";

                                if (this.state.Concept.uri){
                                    const onDone = function(rdfs){
                                        this.setState({rdfs:rdfs});
                                        return;
                                    }.bind(this);
                        
                                    this.dsd.uriDereference(this.state.Concept.uri, onDone);
                                }                
                            }
                            break;                        
                    }
                }
            }

            if (this.state.renderState == "updating"){
                if (this.state.isError){
                    StateChange.renderState = "error";
                    StateChange.isError = false;
                }
                else{
                    if (this.state.isUpdated){
                        StateChange.isUpdated = false;
                        StateChange.renderState = "updated";
                    }
                }
            }

            if (this.state.renderState == "updated"){
                if (this.context.hasLayout){
                    if (this.state.ConceptModel){
                        this.context.Layout.setLeftAsConceptModel(this.state.ConceptModel._id);
                    }
                }
                this.props.navigation(`/concept/${this.state.idConcept}`);
            }

            if (this.state.renderState == "cancelled"){
                switch (this.state.mode){
                    case "edit":
                        this.props.navigation(`/concept/${this.state.idConcept}`);
                        break;
                    case "new":
                        this.props.navigation(this.FallBackNavigation);
                        break;
                }
            }

            if (this.state.renderState == "loaded"){

//                if (this.state.uriDereference){
//                    StateChange.rdfs = this.state.uriDereference;
//                }

                StateChange.OfferEdit = false;
                if (this.state.ConceptModel){
                    if (this.dsd.loggedon){
                        if (this.dsd.user.id == this.state.ConceptModel.idUser){
                            StateChange.OfferEdit = true;
                        }
                    }
                }

                if (!StateChange.OfferEdit){
                    switch (this.state.mode){
                        case "new":
                        case "edit":
                        case "remove":
                            if (!this.state.OfferEdit){
                                StateChange.renderState = "denied"
                                StateChange.errorMessage = "You cannot add or edit Concepts to this Concept Model"
                            }
                            break;
                    }
                }

            }
            this.setState(StateChange);

        }


    }
    
    loadConceptModel(){

        let StateChange = {};
        StateChange.loadConceptModel = false;
        StateChange.isLoadedConceptModel = false;
        StateChange.ConceptModel = null;

        if (this.state.idConceptModel){
            StateChange.renderState = "loading";
            this.dsd.getConceptModel(this.state.idConceptModel,"ConceptModel","isLoadedConceptModel");
        }

        this.setState(StateChange);

        return;
        
    };

    loadConcept(){

        let StateChange = {};
        StateChange.loadConcept = false;
        StateChange.isLoadedConcept = false;
        StateChange.Concept = null;

        StateChange.isLoadedConceptLinks = false;
        StateChange.ConceptLinks = null

        if (this.state.idConcept){
            StateChange.renderState = "loading";
            this.dsd.getConcept(this.state.idConcept,"Concept","isLoadedConcept");
            this.dsd.listConceptLinks({idConcept:this.state.idConcept},"ConceptLinks","isLoadedConceptLinks")
        }

        this.setState(StateChange);

        return;
        
    };



    setup(){

        let StateChange = {

            mode: "view",

            idConcept: null,
            idConceptModel: null,

            ConceptModel: null,
            loadConceptModel: false,
            isLoadedConceptModel: false,

            Concept: null,
            loadConcept: false,
            isLoadedConcept: false,

            isUpdated: false,

            isFailed: false,
            isError: false,
            errorMessage: null,

            renderState: null,

            FormData: {
                status:'in use'
            },

            OfferEdit:false,

            Controls: {
                vizFullSize :true
            },

            countRevisions: 0,

            vizNodeId: null,

            rdfs:null


        }

        if (getParam('mode', this.props)){
            StateChange.mode = getParam('mode', this.props);
        }
        StateChange.idConcept = getParam('id', this.props);
        StateChange.idConceptModel = getParam('idConceptModel', this.props);

        switch (StateChange.mode){
            case 'new':
                if (!StateChange.idConceptModel){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Concept Model not specified when adding a Concept"
                }
                break;
            default:
                if (StateChange.idConceptModel){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Concept Model should not be specified for an existing Concept"
                }
                if (!StateChange.idConcept){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Concept not be specified for an existing Concept"
                }
                break;
        }

        if (StateChange.mode == "new"){
            if (StateChange.idConceptModel){
                this.FallBackNavigation = `/conceptmodel/${StateChange.idConceptModel}`;
            }        
        }



        if (StateChange.idConceptModel){
            StateChange.loadConceptModel = true;
        }

        if (StateChange.idConcept){
            StateChange.loadConcept = true;
        }


        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case 'vizFullSize':
                let Controls = this.state.Controls;
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    
    handleSubmit(event) {

        switch (event.target.name){
            
            case 'btnCancel':

                this.setState({
                    renderState: "cancelled"
                })

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    renderState: "updating"
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('notes' in this.state.FormData){
                    this.state.FormData.notes = sanitizeHtml(this.state.FormData.notes);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addConceptToModel(this.state.idConceptModel,this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editConcept(this.state.idConcept,this.state.FormData);
                        this.refRevision.current.update();
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };
    
        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
        }

        this.setState(StateChange);

        event.preventDefault();
    };


    addSuperConcept(){
        let FormData = this.state.FormData;

        if (!('SuperConcepts' in FormData)){
            FormData['SuperConcepts'] = [];
        }
        if (!FormData.SuperConcepts){
            FormData['SuperConcepts'] = [];
        }

        FormData.SuperConcepts.push({});
        this.setState({"FormData": FormData});
    }

    removeSuperConcept(seq){

        let FormData = this.state.FormData;

        if (!('SuperConcepts' in FormData)){
            FormData['SuperConcepts'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.SuperConcepts.length;pos++){
            let item = FormData.SuperConcepts[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.SuperConcepts = arrNew;
        this.setState({"FormData": FormData});

    }


    updateSuperConcept(seq, SuperConcept){

        let FormData = this.state.FormData;

        if (!('SuperConcepts' in FormData)){
            FormData['SuperConcepts'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.SuperConcepts.length;pos++){
            let item = FormData.SuperConcepts[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = SuperConcept;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(SuperConcept);
        }

        FormData.SuperConcepts = arrNew;
        this.setState({"FormData": FormData});

    }


    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };


    loadedRevisions(count) {

        let StateChange = {};

        StateChange.countRevisions = 0;
        if (count){
            StateChange.countRevisions = count;
        }

        this.setState(StateChange);


    };



    visualise(){
    
        if (!this.state.Concept){
            return null;
        }


        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tr>
                            <td>
                            <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                            </td>
                        </tr>
                    </table>

                    <div name="divViz" style={styleDiv} onClick={this.handleVizClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    render() {

        switch (this.state.renderState){
            case "invalid":
            case "failed":
            case "denied":
                let Message = "An error has occured";
                if (this.state.errorMessage){
                    Message = this.state.errorMessage;
                }
                return <PD.ErrorBox>{Message}</PD.ErrorBox>
                break;
            case "loading":
                return <Buffering/>
                break;
            case "cancelled":
                return <p>Cancelled</p>
                break;
    
        }

/*
        switch (this.state.mode){
            case 'new':
                if (!this.state.isLoadedConceptModel){
                    return <div/>
                }        
                break;
            case 'edit':


            default:

                if (this.state.isFailed){
                    return <PD.ErrorBox>ERROR - Failed to Load</PD.ErrorBox>
                }

                if (!this.state.isLoadedConcept){
                    return <div/>
                }
        }
*/

/*
        switch (this.state.mode){
            case 'remove':
                if (this.state.isUpdated){
                    this.props.navigation(this.FallBackNavigation);
                }
                break;
        }
*/

        switch (this.state.mode){
            case 'new':
            case 'edit':

                if (this.state.Concept){
                    this.state.FormData = this.state.Concept;
                }

                switch (this.state.renderState){
                    case "loaded":
                    case "updating":
                    case "error":
                        return (
                            <div>

                                <PD.Heading>{this.state.mode} Concept</PD.Heading>

                                {this.state.renderState == "error" &&
                                    <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                                }

                                <form> 
                                    <PD.Input label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                                    <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>
                                    <PD.Editor label='Notes' name="notes" value={this.state.FormData.notes} onChange={this.handleChange}/>
                                    <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                                    <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                        {
                                            this.dsd.config.ConceptStatuses.map(
                                                (status, posStatus) => 
                                                    {
                                                        return(
                                                            <option key={posStatus} value={status}>{status}</option>
                                                        )
                                                    }
                                            )
                                        }
                                    </PD.Select>

                                    {this.FormSuperConcepts()}

                                    <Revision ref={this.refRevision}  idAbout={this.state.idConcept}/>
                                    
                                    {(() => {
                                        switch (this.state.renderState) {
                                            case "updating":
                                                return (
                                                    <div>
                                                        <PD.Button disabled={true} type="submit" name="btnSave">
                                                            <Buffering/>
                                                        </PD.Button>
                                                    </div>
                                                )
                                            default:
                                                return (
                                                    <div>
                                                        <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave" value="Save" />    
                                                        <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" /> 
                                                    </div>
                                                )
                                        }
                                    })()}

                                </form>

                            </div>    
                        );
                }
                break;
            case 'remove':
                return (
                    <div>
                        <PD.Heading>{this.state.mode} Concept</PD.Heading>

                        {this.state.isFailed &&
                            <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                        }

                        <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                        <ViewConcept Concept={this.state.Concept}/>
                    </div>
                )
            case 'view':
            default:
                
                switch (this.state.renderState){
                    case "loaded":

                        this.viz = this.dsd.vizConcept({Concept:this.state.Concept, ConceptLinks:this.state.ConceptLinks}, this.state.Controls, this.vizEngine );

                        let countSuperConcepts = 0;
                        if (this.state.Concept.SuperConcepts){
                            countSuperConcepts = this.state.Concept.SuperConcepts.length;
                        }


                        return (
                            <div>
                                <div>
                                    <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                    <PD.Heading>Concept:{this.state.Concept.name}</PD.Heading>
                                </div>

                                {this.state.OfferEdit &&
                                    <span style={{paddingRight:10}}>
                                            <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                    </span>
                                }

                                <ViewConcept Concept={this.state.Concept}/>

                                <br/>

                                <PD.Tabs>


                                    <div label='Visualise'>
                                        <table>
                                            <tr>
                                                <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                    {this.visualise()}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>


                                    <div label='Concept Model'>
                                        <ViewConceptModel ConceptModel={this.state.ConceptModel} />
                                    </div>

                                    <div label={`Super Concepts(${countSuperConcepts})`}>

                                        <ListConcepts filters={{"idSubConcept":this.state.idConcept}} columns={{conceptmodel:true, owner:true}} />

                                    </div>


                                    <div label='Metadata'>
                                        <ViewMetadata Data={this.state.Concept} />
                                    </div>

                                    <div label={`Revisions(${this.state.countRevisions})`} forceRender={true}>
                                        <ListRevisions OfferEdit={this.state.OfferEdit} filters={{idAbout:this.state.idConcept}} onLoad={this.loadedRevisions}/>
                                    </div>


                                    <div label="used in ...">
                                    </div>

                                    <div label='Developer'>
                                        <table>
                                            {this.viz &&
                                                <tr>
                                                    <th style={{verticalAlign:"top"}}>dot</th>
                                                    <td><pre>{this.viz.dot}</pre></td>
                                                </tr>
                                            }
                                            {this.state.csv &&
                                                <tr>
                                                        <th style={{verticalAlign:"top"}}>csv</th>
                                                        <td><pre>{this.state.csv}</pre></td>
                                                </tr>
                                            }
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>rdfs</th>
                                                <td><pre>{this.state.rdfs}</pre></td>
                                            </tr>

                                        </table>

                                    </div>


                                </PD.Tabs>


                            </div>
                        )
                }
        };

        return <div />;
    };


    FormSuperConcepts(){

        let seqNext = 1;
        if (this.state.FormData.SuperConcepts){
            seqNext = this.state.FormData.SuperConcepts.length + 1;
        }
        return (
            <fieldset>
                <legend>Super Concepts</legend>
                <PD.Table type="list">
                    {this.state.FormData.SuperConcepts &&
                        this.state.FormData.SuperConcepts.map(
                            (SuperConcept, posSuperConcept) => 
                            {
                                let seq = posSuperConcept+1;
                                return(
                                    this.FormSuperConcept('edit', seq, SuperConcept)
                                )
                            }
                        )
                    }
                    {this.FormSuperConcept('new', seqNext)}
                </PD.Table>
            </fieldset>
        );

    }

    FormSuperConcept(mode, seq, SuperConcept = {}){
        //!! does it need to send Conept ?? //
        return(
            <SuperConceptRow key={seq.toString()} mode={mode} parent={this} SuperConcept={SuperConcept} seq={seq} Concept={this.state.Concept}/>
        )
    }


};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Concept {...props} navigation={navigation} location={location} params={params} />;
  }
