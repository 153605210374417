import {HtmlToText} from '../utils/utils';
import { json2csv } from 'json-2-csv';

export const makeCsv = (ConceptModel = null) => {

    if (!ConceptModel){
        return null;
    }

    if (!ConceptModel.Concepts){
        return null;
    }


    let rows = [];
    
    for (let posC=0;posC<ConceptModel.Concepts.length;posC++){
        const Concept = ConceptModel.Concepts[posC];
        let row = {};
        let rowPrefix = '';
        if (ConceptModel.prefix){
            rowPrefix = `${ConceptModel.prefix}:`
        }
        row.Concept = `${rowPrefix}${Concept.name.replace(/ /g,"_")}`;
        row.Label = Concept.name;
        row.Description = HtmlToText(Concept.description);
        row.SuperConcepts = '';
        for (let posS=0;posS<Concept.SuperConcepts.length;posS++){
            const SuperConcept = Concept.SuperConcepts[posS];
            if (SuperConcept.Concept){
                if (row.SuperConcepts){
                    row.SuperConcepts += `\r\n`;
                }
                row.SuperConcepts += `${SuperConcept.Concept.ConceptModel.prefix}:${SuperConcept.Concept.name.replace(/ /g,"_")}`
            }
        }
        rows.push(row);
    }

    
    rows.sort((a, b) => {
        const ConceptA = a.Concept.toUpperCase();
        const ConceptB = b.Concept.toUpperCase();
        if (ConceptA < ConceptB) {
            return -1;
        }
        if (ConceptA > ConceptB) {
            return 1;
        }
        
        // names must be equal
    return 0;
    });
                
    const csv = json2csv(rows);
    
    return csv;

}